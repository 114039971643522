import appHeader from "@/components/Header/header.vue";
import findAssociation from "@/components/findAssociation/findAssociation.vue";
import plainBtn from "@/components/buttons/plainBtn/plainBtn.vue";
import chapter1 from "@/json/chapter1.json";
import sleep from "@/helpers/sleep";
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "chapterOne",
  components: {
    appHeader,
    findAssociation,
    plainBtn
  },
  props: {
    wordIndex: {
      default: 1
    }
  },
  data() {
    return {
      json: chapter1,
      isLoaded: false
    };
  },
  async mounted() {
    const numberOfSteps = await this.$store.dispatch(
      "initExercice",
      chapter1.properties
    );

    if (numberOfSteps !== null && this.parsedWordIndex <= numberOfSteps) {
      await this.$store.dispatch(
        "setActualStep",
        this.parsedWordIndex - 1
      );
      this.isLoaded = true;
    }
  },
  computed: {
    parsedWordIndex() {
      return parseInt(this.wordIndex);
    },
    stepWord() {
      return this.actualStep ? this.actualStep.name : "";
    },
    stepIcon() {
      return this.actualStep ? this.actualStep.icon : null;
    },
    lastStepBeforeShowSynthesis() {
      return (
        this.numberOfSteps !== null &&
        this.parsedWordIndex === this.numberOfSteps
      );
    },
    showSynthesis() {
      return this.wordIndex === "synthesis";
    },
    progress() {
      return this.showSynthesis
        ? 100
        : ((this.parsedWordIndex - 1) * 100) / this.numberOfSteps;
    },
    ...mapGetters(["availableResponses", "actualStep", "numberOfSteps"])
  },
  methods: {
    async nextQuestion() {
      await sleep(200);
      if (document.activeElement instanceof HTMLElement)
        document.activeElement.blur();
      if (this.lastStepBeforeShowSynthesis) {
        this.$router.push({
          name: "Chapter-one",
          query: { q: "synthesis" }
        });
      } else {
        this.$router.push({
          name: "Chapter-one",
          query: { q: this.parsedWordIndex + 1 }
        });
      }
    },
    async removeLastAnswer() {
      await this.$store.dispatch("removeLastAnswer", this.parsedWordIndex);
    }
  },
  watch: {
    async wordIndex(actual) {
      if (this.numberOfSteps !== null && actual <= this.numberOfSteps) {
        await this.$store.dispatch("setActualStep", actual - 1);
      }
    }
  }
};
